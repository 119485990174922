import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductSelect from "../../components/ProductSelect/product-select";
import { tearribleStyles } from "../../types";

class GearSelect extends Component {
	render() {
		return (
			<ProductSelect
				title="Parts Department"
				{...this.props}
				sort={(a, b) => {
					if (!a.size || !b.size) return 0;

					const aSize = tearribleStyles[a.size]?.index || 99;
					const bSize = tearribleStyles[b.size]?.index || 99;

					if (aSize < bSize) return -1;
					if (aSize > bSize) return 1;
					return 0;
				}}
				displayPrice={false}
				description={[
					"Mistakes happen. Fun gets out of hand. Things get ruined. Body parts get lost. Maybe you just want to channel your Dr. Frankenstein and build your own monster (this is a judgment-free zone).",
					"Welcome to the Tearribles parts department, where we make it possible.",
				]}
			/>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query partsQuery {
				allShopifyCollection(
					filter: { title: { in: "Individual Parts" } }
				) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								# images {
								#     originalSrc
								#     localFile {
								#         childImageSharp {
								#             fluid {
								#                 ...GatsbyImageSharpFluid_withWebp_noBase64
								#             }
								#         }
								#     }
								# }
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									title
									id
									shopifyId
									legacyResourceId
									sku
									price
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <GearSelect data={data} {...props} />}
	</StaticQuery>
);
